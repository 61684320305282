<template>
    <div class="max-w-screen-2xl mx-auto">
        <Content
            v-if="preview"
            model="page"
            :content="content"
            :api-key="apiKey" 
            :customComponents="REGISTERED_COMPONENTS"
        />
  </div>
</template>

<script setup lang="ts">
import { REGISTERED_COMPONENTS } from '~/builder-page.ts';
import { Content, fetchOneEntry } from '@builder.io/sdk-vue'

const apiKey = useAppConfig().builderKey
const route = useRoute()
const { data: content } = await useAsyncData('builderData', () =>
    fetchOneEntry({
        model: 'page',
        apiKey: apiKey,
        includeUnpublished: true,
        userAttributes: {
            urlPath: route.path,
        },
    })
)

const preview = computed(() => {
    return content !== null || window.location.search.indexOf("builder.frameEditing=") !== -1
})


</script>